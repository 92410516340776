<template>
    <!-- 宝塑 -->
    <div id="CustomerDZMotor">
      <!-- CustomerDZMotor头部内容 -->
      <div class="CustomerDZMotorHeader">
        <!-- 头部组件 -->
        <Header />
      </div>
      <!-- CustomerDZMotor中间内容 -->
      <div class="CustomerDZMotorCenter">
        <!-- 客户介绍 -->
        <div class="contentOne">
          <div class="titleOne">客户介绍</div>
          <div class="conInfo">
            <img src="~assets/img/CustomerBS/公司@2x.png" />
            <div class="infoOne">
              &#160;&#160;&#160;&#160;&#160;  浙江宝塑环保科技有限公司是一家从事技术服务,技术开发,技术咨询等业务的公司，成立于2022年06月13日，公司坐落在浙江省舟山市岱山县东沙镇创业大道68号注册资本为5500.000000万人民币。企业的主要经营：资源再生利用技术研发；技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；塑料制品销售；塑料制品制造；工程塑料及合成树脂销售；包装专用设备制造；模具制造；专用设备制造（不含许可类专业设备制造）；合成材料制造（不含危险化学品）；新材料技术研发；塑料包装箱及容器制造；货物进出口等。
            </div>
          </div>
        </div>
        <!-- 项目内容 -->
        <div class="contentTwo">
          <div class="titleTwo">项目内容</div>
          <div class="titleT">
            引进智能化中央供料系统、可持续性集成供水系统、自动传输机、智能机器人以及污水处理区、尾气处理等自动设施设备。建设公司内部5G网络互联互通，在核心机房部署硬件控制器或者虚拟控制器统一管理网络中的交换机和无线AP。通过全厂无线网络敷设，以及设备数据自动采集联网联动。以100%自动化生产，100%设备数据采集为基础，建设集CAD、SCADA、ERP、MES于一体的数字化管控系统。实现网上订单，供应链管理、客户管理、进销存、仓库调拨，实时生产查询，渠道管理，应收应付等功能。建立设备监控看板、手机小程序可视化、智能工厂驾驶舱。
          </div>
        </div>
    
      <!-- 项目成效 -->
      <div class="contentTwo" style="margin-top: 80px;">
                <div class="titleTwo">项目成效</div>
                <div class="titleT">
                  通过数字化管理、自动化生产的运行，降低了产品生产能耗，缩短了产品生产周期、减低了生产人员的需求、提高了产品质量。     
                </div>
            </div>

            <!-- 项目概况 -->
            <div class="contentThree">
                <!-- <div class="titleThree">项目概况</div> -->
                <ul class="imgContent">
                    <li v-for="(item, index) in imgContents" :key="index">
                        <img :src="item.Imgs" style="width: 550px;height: 324px;" />
                        <!-- <div class="imgBottom">{{ item.textPro }}</div> -->
                    </li>
                </ul>
            </div>
        </div>
      <!-- 底部内容 -->
      <Footer />
      <!-- 回到顶部 -->
      <el-backtop
        ><img
          src="~assets/img/CustomerDZMotor/icon-一键置顶@2x.png"
          alt="icon-回到顶部@2x.png"
      /></el-backtop>
    </div>
  </template>
  <script>
  import Header from "components/Header/dsHeader";
  import Footer from "components/Footer/dsFooter";
  export default {
    name: "CustomerDZMotor",
    data() {
      return {
        imgContents: [
          {
            Imgs: require("assets/img/board/bs/01.png"),
            textPro: ""
          },{
            Imgs: require("assets/img/board/bs/02.png"),
            textPro: ""
          },{
            Imgs: require("assets/img/board/bs/03.png"),
            textPro: ""
          },{
            Imgs: require("assets/img/board/bs/04.png"),
            textPro: ""
          },
        ],
       
      };
    },
    mounted() {  
      document.title = '岱山汽船配工业互联网平台';   
  },
    components: {
      Header,
      Footer
    }
  };
  </script>
  <style lang="less" scoped>
#CustomerDZMotor {
  width: 1583px;
  background-color: #f8f9fb;
  margin: 0 auto;

  // CusCustomerDZMotor头部样式
  .CustomerDZMotorHeader {
    width: 1583px;
    height: 620px;
    background: url("~assets/img/CustomerBS/banner@2x.jpg") no-repeat;
    background-size: 1583px 620px;
    background-position: center;
    margin: 0 auto;
  }

  // CustomerDZMotor中间内容样式
  .CustomerDZMotorCenter {
    width: 1220px;
    margin: 80px auto 101px;

    // 客户介绍
    .contentOne {
      .titleOne {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }

      .conInfo {
        margin-top: 30px;
        display: flex;

        img {
          width: 560px;
          height: 343px;
        }

        .infoOne {
          width: 590px;
          height: 157px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 28px;
          color: rgba(51, 51, 51, 0.85);
          margin: 3px 0 0 40px;
        }
      }
    }

    // 项目价值
    .contentTwo {
      margin-top: 60px;

      .titleTwo {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 28px;
        color: #333333;
      }

      .titleT {
        width: 1200px;
        // height: 72px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 28px;
        color: rgba(51, 51, 51, 0.85);
        margin-top: 30px;
      }
    }

    // 项目概况
    .contentThree {

      // margin-top: 60px;
      .titleThree {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }

      .imgContent {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        // margin-top: 30px;
        padding: 0;

        li {
          margin-bottom: 30px;
          background-color: #fff;



          .imgBottom {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: rgba(51, 51, 51, 0.85);
            margin-top: 15px;
            text-align: center;
          }
        }
      }
    }
  }
}</style>
  <style lang="less">
  .el-backtop {
    img {
      width: 30px;
      height: 30px;
    }
  }
  </style>
  